<template>
  <LateralMenuItem
    :title="$t('MarketSegmentAndTeamSizeFilter.title')"
    :is-mini="isMini"
  >
    <template #title-icon="{ size }">
      <v-icon :size="size">mdi-flash</v-icon>
    </template>

    <ComposeForm
      ref="ComposeForm"
      class="filter"
      :form-name="filterName"
      :selectors="{ fields: _fields }"
      :storage-preferences="false"
      v-bind="ComposeFormProps()"
      @compose:values="setFiltersValue($event)"
    ></ComposeForm>
  </LateralMenuItem>
</template>

<script>
import {
  handleAxiosSignalAbortController,
  handleApiRequestWithController,
} from '@/helpers/api'

export default {
  name: 'MarketSegmentAndTeamSizeFilter',
  props: {
    filterName: {
      type: String,
      required: true,
    },
    isMini: Boolean,
  },
  data() {
    return {
      form: {
        filter: {},
      },

      fields: [
        {
          id: 'marketSegment',
          _label: this.$t('MarketSegmentAndTeamSizeFilter.marketSegment.label'),
          type: 'ItemSelector',
          submit: {
            type: 'arrayOf',
            identifier: 'id',
          },
          ItemSelector: {
            multiple: false,
            localSearch: true,
            inputConfig: {
              closeOnSelect: true,
              showAvatar: false,
              clearable: true,
            },
            menuConfig: {
              align: 'right',
              attach: false,
              showTabs: false,
            },
          },
          options: [
            {
              value: 'marketSegment',
              label: '',
              type: 'listview',
              items: [
                {
                  label: this.$t(
                    'MarketSegmentAndTeamSizeFilter.marketSegment.values.industry'
                  ),
                  id: 'industry',
                },
                {
                  label: this.$t(
                    'MarketSegmentAndTeamSizeFilter.marketSegment.values.retail'
                  ),
                  id: 'retail',
                },
                {
                  label: this.$t(
                    'MarketSegmentAndTeamSizeFilter.marketSegment.values.education'
                  ),
                  id: 'education',
                },
                {
                  label: this.$t(
                    'MarketSegmentAndTeamSizeFilter.marketSegment.values.technology'
                  ),
                  id: 'technology',
                },
                {
                  label: this.$t(
                    'MarketSegmentAndTeamSizeFilter.marketSegment.values.construction'
                  ),
                  id: 'construction',
                },
                {
                  label: this.$t(
                    'MarketSegmentAndTeamSizeFilter.marketSegment.values.other'
                  ),
                  id: 'other',
                },
              ],
            },
          ],
        },
        {
          id: 'teamSize',
          _label: this.$t('MarketSegmentAndTeamSizeFilter.teamSize.label'),
          type: 'ItemSelector',
          submit: {
            type: 'arrayOf',
            identifier: 'id',
          },
          ItemSelector: {
            multiple: false,
            localSearch: true,
            inputConfig: {
              closeOnSelect: true,
              showAvatar: false,
              clearable: true,
            },
            menuConfig: {
              align: 'right',
              attach: false,
              showTabs: false,
            },
          },
          options: [
            {
              value: 'teamSize',
              label: '',
              type: 'listview',
              items: [
                {
                  label: this.$t(
                    'MarketSegmentAndTeamSizeFilter.teamSize.values.1to100'
                  ),
                  id: '1-100',
                },
                {
                  label: this.$t(
                    'MarketSegmentAndTeamSizeFilter.teamSize.values.100to500'
                  ),
                  id: '100-500',
                },
                {
                  label: this.$t(
                    'MarketSegmentAndTeamSizeFilter.teamSize.values.500to1000'
                  ),
                  id: '500-1000',
                },
                {
                  label: this.$t(
                    'MarketSegmentAndTeamSizeFilter.teamSize.values.1000to5000'
                  ),
                  id: '1000-5000',
                },
                {
                  label: this.$t(
                    'MarketSegmentAndTeamSizeFilter.teamSize.values.5000toMore'
                  ),
                  id: '5000-more',
                },
              ],
            },
          ],
        },
      ],
    }
  },
  computed: {
    _fields() {
      return this.fields
    },
  },
  methods: {
    setFiltersValue(values) {
      this.$set(this.form, 'filter', values)
      this.sendFilterValues(values)
    },

    sendFilterValues(values) {
      let filters = structuredClone(values)

      Object.keys(filters).forEach((key) => {
        if (['marketSegment', 'teamSize'].includes(key)) {
          filters[key] = Array.isArray(filters[key]) ? filters[key]?.[0] : null
        }
      })

      this.$root.$emit('market-segment-and-team-size:filter-changes', filters)
    },

    ComposeFormProps() {
      return {
        handleAxiosSignalAbortController: handleAxiosSignalAbortController,
        handleApiRequestWithController: handleApiRequestWithController,
      }
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
