import { getMetadataGroups, getMetadata } from '@/services/metadata'
import { getPeople } from '@/services/people'

export async function getMetadatasOnGroups(payload) {
  const [metadataGroupsResponse, metadatasResponse] = await Promise.all([
    getMetadataGroups(payload),
    getMetadata(payload),
  ])

  const metadatas = metadatasResponse.data

  if (
    !metadataGroupsResponse.data ||
    metadataGroupsResponse.data.length === 0
  ) {
    return []
  }

  const idOfDefaultGroup = metadataGroupsResponse.data.find(
    group => group.default === true
  )

  const metadataGroups = new Map(
    metadataGroupsResponse.data.map(group => [
      group.id,
      {
        ...group,
        metadatasPositions: new Map(
          group?.metadatasPositions?.map(metaPosition => [
            metaPosition.id,
            metaPosition.position,
          ])
        ),
        items: [],
      },
    ])
  )

  const orphanMetadatas = []
  metadatas.forEach(metadata => {
    if (metadataGroups.has(metadata.group?.id)) {
      const section = metadataGroups.get(metadata.group.id)

      const metadataPosition = section.metadatasPositions.get(metadata.id)
      if (typeof metadataPosition === 'number' && !isNaN(metadataPosition)) {
        section.items[metadataPosition] = metadata
        return
      }

      orphanMetadatas.push(metadata)
      return
    }

    orphanMetadatas.push(metadata)
  })

  const section = metadataGroups.get(idOfDefaultGroup.id)
  section.items.push(...orphanMetadatas)

  return Array.from(metadataGroups, ([, value]) => ({
    ...value,
    items: value.items.filter(Boolean),
  }))
}

function handleMetadataDefaultSelect(itens, options) {
  return itens.map(item => {
    const itemID = typeof item === 'object' ? item.id : item

    const possibleMatch = options.find(option => option.id === itemID)

    if (possibleMatch) {
      return possibleMatch
    }

    return item
  })
}

function handleMetadataDefaultPeople(items) {
  return items.map(hidratePeopleFn)
}

export function calcMetadataDefaults(field, metadatasWithValue) {
  if (!Array.isArray(metadatasWithValue)) {
    return null
  }

  const possibleMatch = metadatasWithValue.find(
    metadata => metadata.id === field.id
  )

  if (!possibleMatch) {
    return null
  }

  const OutputByType = {
    select: meta =>
      hidrateSelectItems(
        handleMetadataDefaultSelect(meta.values, field.options)
      ),
    people: meta => handleMetadataDefaultPeople(meta.people),
    date: meta => meta.date,
    text: meta => meta.text,
  }

  return OutputByType[field.type](possibleMatch)
}

export function calcMetadataGroupValues(
  group,
  values,
  ignoreReadonly = true,
  ignoreNonDirty = false
) {
  const arrayOfIDs = items => {
    return items.map(item => ({ id: item }))
  }

  const OutputByType = {
    select: items => ({ values: arrayOfIDs(items) }),
    people: items => ({ people: arrayOfIDs(items) }),
    date: value => ({ date: value }),
    text: value => ({ text: value }),
  }

  const FormatedValues = {}
  Object.entries(values).forEach(([key, value]) => {
    const field = group.selectors.fields.find(f => f.id === key)
    if (ignoreReadonly && field?.isReadonly) {
      return
    }

    const isEmpty = !value.length
    const nonDirty = field?.default === null
    if (ignoreNonDirty && nonDirty && isEmpty) {
      return
    }

    if (ignoreNonDirty && nonDirty && !isEmpty) {
      const defaultByType = {
        select: [],
        people: [],
        date: '',
        text: '',
      }

      field.default = defaultByType[field?.metadataType]
    }

    const output = OutputByType[field?.metadataType](value) || {}
    const payload = {
      id: key,
      ...output,
    }

    FormatedValues[key] = payload
  })

  return FormatedValues
}

export function calcNonStrictSubmit(metadataType, strict = false) {
  if (strict) {
    return {}
  }

  const formatByType = {
    select: 'arrayOf',
    people: 'arrayOf',
    date: 'string',
    text: 'string',
  }[metadataType]

  return {
    submit: {
      type: formatByType,
      identifier: 'id',
    },
  }
}

export function hidrateSelectItems(items) {
  return items.map(item => ({
    ...item,
    label: item.value,
  }))
}
export function constructFieldSelect(
  field,
  readonly = false,
  subtleMode = false
) {
  return {
    type: 'ItemSelector',

    options: [
      {
        value: field.id,
        label: field.label,
        type: 'listview',
        items: hidrateSelectItems(field.options),
      },
    ],

    ItemSelector: {
      localSearch: true,

      menuConfig: {
        attach: true,
        showTabs: false,
      },
      inputConfig: {
        subtleMode: subtleMode,
        highlight: !!field?.important,

        showAvatar: false,
        rules: field.required ? ['required'] : [],
        clearable: !readonly && !field.required,
        closeOnSelect: !field.multi,
      },
      persistent: field.required,

      type: 'listview',
      multiple: field.multi,
      readonly: readonly,
    },
  }
}

export function calcPersonInactive(person) {
  if (!person) return false
  return !!(person.status === 'inactive' || person?.inactive)
}

export function hidratePeopleFn(item) {
  return {
    ...item,
    image: item.photo,
    label: item.name,
    inactive: calcPersonInactive(item),
  }
}
export function constructFieldPeople(
  field,
  readonly = false,
  subtleMode = false
) {
  return {
    type: 'ItemSelector',

    ItemSelector: {
      localSearch: false,

      menuConfig: {
        attach: true,
        showTabs: false,
        showElements: true,
      },
      inputConfig: {
        subtleMode: subtleMode,
        highlight: !!field?.important,

        showAvatar: true,
        rules: field.required ? ['required'] : [],
        clearable: !readonly && !field.required,
        closeOnSelect: !field.multi,
      },
      persistent: field.required,

      type: 'listview',
      multiple: field.multi,
      readonly: readonly,
    },

    api: {
      endpoint: getPeople,
      searchKey: 'name',
      limit: 20,
      infinity: true,
      identifier: 'id',
      hidrateFn: hidratePeopleFn,
    },
  }
}

export function constructFieldText(
  field,
  readonly = false,
  subtleMode = false
) {
  return {
    type: 'CustomInput',
    CustomInput: {
      viewMode: subtleMode,
      highlight: !!field?.important,

      type: 'text',
      readonly: readonly,
      mandatory: field.required,
    },
  }
}

export function constructFieldDate(
  field,
  readonly = false,
  subtleMode = false
) {
  return {
    type: 'CustomDatePicker',
    CustomDatePicker: {
      menuConfig: {
        attach: true,
      },
      inputConfig: {
        closeOnClick: true,
        rules: field.required ? ['required'] : [],
        subtleMode: subtleMode,
        highlight: !!field?.important,
      },
      readonly: readonly,
    },
  }
}
