import api from '@/services/Http'

export async function putGoalGroupsMeta(goalGroupID, params) {
  return (await api()).put(
    `/performance/goals/goal-groups/:goalGroupID`,
    params,
    {
      _params: {
        goalGroupID,
      },
    }
  )
}

export async function postGoalGroupsMeta(params) {
  return (await api()).post(`/performance/goals/goal-groups`, params)
}

export async function getGoalGroupsMeta(data) {
  return (await api()).get(`/performance/goals/goal-groups`, { params: data })
}

export async function getGoalGroupsMetaByID(groupID) {
  return (await api()).get(`/performance/goals/goal-groups/:groupID`, {
    _params: {
      groupID,
    },
  })
}

export async function deleteGoalGroupsMetaByID(groupID) {
  return (await api()).delete(`/performance/goals/goal-groups/:groupID`, {
    _params: {
      groupID,
    },
  })
}
