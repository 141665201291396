<template>
  <div class="management-group-from-meta" data-test-management-group-from-meta>
    <v-card class="card">
      <div class="header">
        <div>
          <div class="header-title">
            <v-icon class="crown">fi-sr-crown</v-icon>
            <h1>{{ $t('PerformancePage.managementGroupFromMeta.title') }}</h1>

            <HowToBeginAndWatchAVideo
              video-type="urlHelperlistGroupFromMeta"
              short
              :has-video="false"
            ></HowToBeginAndWatchAVideo>
          </div>
          <div class="header-subtitle">
            <h3>
              {{ $t('PerformancePage.managementGroupFromMeta.subtitle') }}
            </h3>
          </div>
        </div>

        <div class="btn-container">
          <div
            v-if="_canCreateGroupFromMeta"
            id="add-new-btn"
            data-test-add-new-group-from-meta
            class="add-new-btn add-new-btn-size add-new-btn-primary"
            @click="handleCreateGroupFromMeta"
          >
            <v-icon dark size="1rem">mdi-plus</v-icon>
            {{
              $t(
                'PerformancePage.managementGroupFromMeta.btnAddNewGroupFromMeta'
              )
            }}
          </div>
        </div>
      </div>

      <v-divider></v-divider>

      <v-row>
        <v-col>
          <DataTable
            height="12rem"
            :title="$t('PerformancePage.managementGroupFromMeta.searchLabel')"
            :headers="_headers"
            :items="groups"
            :hide-option="true"
            :content-style="false"
            :hide-header="true"
            :items-per-page="pagination.limit"
            :total-items="totalItems"
            :loading="loading"
            :skeleton-lines="4"
            :empty-placeholder="
              $t('PerformancePage.managementGroupFromMeta.emptyPlaceholder')
            "
            @get-data="handleSearch($event)"
            @search="handleSearch($event)"
            @remove="handleDeleteGroup($event)"
            @menu-options="showMenuAction"
            @edit="handleEditGroupFromMeta"
          >
            <template #menu="{ position }">
              <MoreOptions
                class="moreOptions"
                :show="menuOpened && position === menuOpenedIndex"
                :dots="false"
                :options="_moreOptionsItens"
                :left-or-right="false"
                @handleClose="handleMenuClose"
                @handleAction="handleMenuAction($event, position)"
              ></MoreOptions> </template
          ></DataTable>
        </v-col>
      </v-row>

      <DeleteModalGroupFromMeta
        ref="deleteModal"
        @handle-delete="handleDeleteGroupFromMeta($event)"
      ></DeleteModalGroupFromMeta>
    </v-card>
  </div>
</template>

<script>
import {
  getGoalGroupsMeta,
  deleteGoalGroupsMetaByID,
  putGoalGroupsMeta,
} from '@/services/groups-meta'
import { alertErrorBus, alertSuccessBus } from '@/helpers/alerts'

import _colors from '@/styles/abstracts/_colors.module.scss'

import DeleteModalGroupFromMeta from '@/views/ManagementPage/Tabs/AccountPage/Tabs/PerformancePage/Parts/ManagementGroupFromMeta/Partials/DeleteModalGroupFromMeta/DeleteModalGroupFromMeta.vue'

import {
  management_performance_goalgroup_create,
  management_performance_goalgroup_edit,
  management_performance_goalgroup_delete,
} from '@/helpers/ability'

export default {
  name: 'ManagementGroupFromMeta',

  components: {
    DeleteModalGroupFromMeta,
  },

  data() {
    return {
      menuOpened: false,
      menuOpenedIndex: 0,
      loading: false,
      totalItems: 0,
      search: null,
      pagination: {
        name: null,
        limit: 4,
        offset: 0,
        sortBy: 'createdAt',
        sortOrder: 'desc',
      },
      groups: [],
      groupSelected: null,
    }
  },

  computed: {
    _headers() {
      return [
        {
          text: this.$t('PerformancePage.managementGroupFromMeta.headers.name'),
          value: 'title',
          type: 'text-status',
          width: '70%',
        },
        {
          text: this.$t(
            'PerformancePage.managementGroupFromMeta.headers.status'
          ),
          value: 'status',
          type: 'text-status',
          width: '25%',
        },
        {
          text: this.$t(
            'PerformancePage.managementGroupFromMeta.headers.actions'
          ),
          value: 'actions',
          type: 'action-buttons',
        },
      ]
    },
    _moreOptionsItens() {
      const isActiveStatus = this.groupSelected?.groupObject.inactive === false
      const isInactiveStatus = this.groupSelected?.groupObject.inactive === true

      const actions = [
        {
          permission: this._canEditGroupFromMeta,
          status: !isActiveStatus,
          name: this.$t(
            'PerformancePage.managementGroupFromMeta.status.activeAction'
          ),
          icon: 'fi-rr-power',
          action: 'active',
        },
        {
          permission: this._canEditGroupFromMeta,
          status: !isInactiveStatus,
          name: this.$t(
            'PerformancePage.managementGroupFromMeta.status.inactiveAction'
          ),
          icon: 'fi-rr-power',
          action: 'inative',
        },
        {
          permission: this._canDeleteGroupFromMeta,
          status: true,
          name: this.$t(
            'PerformancePage.managementGroupFromMeta.status.deleteAction'
          ),
          icon: 'fi-rr-trash',
          action: 'delete',
        },
      ]

      const items = actions.filter(action => action.permission && action.status)

      return items
    },
    _canCreateGroupFromMeta() {
      return this.$can('access', management_performance_goalgroup_create)
    },
    _canEditGroupFromMeta() {
      return this.$can('access', management_performance_goalgroup_edit)
    },
    _canDeleteGroupFromMeta() {
      return this.$can('access', management_performance_goalgroup_delete)
    },
  },

  beforeMount() {
    this.$root.$on('handle-group-from-meta', this.fetchGroupMeta)
  },

  beforeDestroy() {
    this.$root.$off('handle-group-from-meta')
  },

  methods: {
    fetchGroupMeta() {
      this.handleGroupsMeta({
        ...this.pagination,
        title: this.search,
      })
    },
    handleSearch(event) {
      this.search = event.name !== '' ? event.name : null
      this.handlePagination(event)

      const payload = {
        title: this.search,
        ...this.pagination,
      }

      this.handleGroupsMeta(payload)
    },
    handlePagination(event) {
      this.pagination.offset = event.offset
    },

    async handleGroupsMeta(payload) {
      try {
        this.loading = true
        const { data, headers } = await getGoalGroupsMeta(payload)

        this.totalItems = parseInt(headers['x-count']) || 0

        this.handleTableAssembly(data)

        this.loading = false
      } catch (error) {
        alertErrorBus(
          this.$t('PerformancePage.managementGroupFromMeta.alert.errorList'),
          error
        )
      }
    },
    handleTableAssembly(listGroups) {
      const statusMap = {
        false: {
          label: this.$t(
            'PerformancePage.managementGroupFromMeta.status.active'
          ),
          color: _colors.greenColor,
        },
        true: {
          label: this.$t(
            'PerformancePage.managementGroupFromMeta.status.inactive'
          ),
          color: _colors.neutral5,
        },
      }

      this.groups = listGroups.map(group => {
        return {
          id: group.id,
          title: {
            label: group.title,
          },
          status: statusMap[group.inactive] || {
            label: '--',
            color: '',
          },
          actions: this.handleActions(),
          groupObject: group,
        }
      })
    },
    handleActions() {
      let actions = []
      if (this._canEditGroupFromMeta) actions.push('edit')
      if (this._canDeleteGroupFromMeta || this._canEditGroupFromMeta)
        actions.push('menu-options')

      return actions
    },
    showMenuAction(event, position) {
      this.groupSelected = event
      this.menuOpenedIndex = position
      this.menuOpened = true
    },
    handleMenuClose() {
      this.menuOpened = false
      this.menuOpenedIndex = 0
    },
    handleMenuAction(event, position) {
      this.handleMenuClose()

      switch (event) {
        case 'inative':
          return this.inativeGroupStatus(this.groups[position].groupObject)
        case 'active':
          return this.activeGroupStatus(this.groups[position].groupObject)
        case 'delete':
          return this.handleDeleteGroup(this.groups[position])
      }
    },
    inativeGroupStatus(groupMeta) {
      const payload = {
        ...groupMeta,
        inactive: true,
      }

      this.editGroupFromMeta(groupMeta.id, payload)
    },
    activeGroupStatus(groupMeta) {
      const payload = {
        ...groupMeta,
        inactive: false,
      }

      this.editGroupFromMeta(groupMeta.id, payload)
    },
    async editGroupFromMeta(groupMetaID, payload) {
      delete payload.id

      await putGoalGroupsMeta(groupMetaID, payload)
        .then(() => {
          alertSuccessBus(
            this.$t('PerformancePage.groupFromMetaForm.alert.updateSuccess')
          )
          this.fetchGroupMeta()
        })
        .catch(error => {
          alertErrorBus(
            this.$t('PerformancePage.groupFromMetaForm.alert.updateError'),
            error
          )
        })
    },
    handleDeleteGroup(groupID) {
      this.$refs.deleteModal.handleOpen(groupID)
    },
    async handleDeleteGroupFromMeta(groupID) {
      try {
        await deleteGoalGroupsMetaByID(groupID)

        this.fetchGroupMeta()

        alertSuccessBus(
          this.$t(
            'PerformancePage.deleteModalGroupFromMeta.alert.deleteSuccess'
          )
        )
      } catch (error) {
        alertErrorBus(
          this.$t('PerformancePage.deleteModalGroupFromMeta.alert.deleteError'),
          error
        )
      }
    },
    handleCreateGroupFromMeta() {
      this.$emit('handle-create-group-from-meta')
    },
    handleEditGroupFromMeta(groupFromMetaID) {
      this.$emit('edit-group-from-meta', groupFromMetaID)
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
