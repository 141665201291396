<template>
  <div data-test-notification-menu class="notification">
    <NotificationMenu
      :is-mini-variant="isMini"
      :notifications="_notifications"
      :unreaded-count="unreadedCount"
      @content:click="handleNotificationContentClick"
      @handle-scroll="handleScroll"
      @read-notification:id="handleRead"
      @read-all="markAllRead"
      @show-all="showAll"
    ></NotificationMenu>
  </div>
</template>

<script>
import {
  getNotifications,
  markAsRead,
  markAsUnread,
  markAllAsRead,
} from '@/service/notifications'

import { handleDefaultNotificationDataToItems } from '@/helpers/notifications'

export default {
  name: 'NotificationView',
  props: {
    isMini: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      interval: null,
      notifications: [],
      paginationNotification: {
        limit: 10,
        offset: 0,
        total: 0,
      },
      unreadedCount: 0,

      readNotificationIsLoading: false,
    }
  },

  computed: {
    _notifications() {
      return this.notifications
    },
  },

  mounted() {
    this.getNotifications({
      limit: this.paginationNotification.limit,
      offset: 0,
    })
    this.interval = setInterval(() => {
      this.getNotifications({
        limit: this.notifications.length || this.paginationNotification.limit,
        offset: 0,
      })
    }, 300000)
  },

  beforeDestroy() {
    clearInterval(this.interval)
  },

  methods: {
    showAll() {
      return window.location.assign(`/notification`)
    },

    async markAllRead() {
      await markAllAsRead()
      this.getNotifications({
        limit: this.notifications.length || this.paginationNotification.limit,
        offset: 0,
      })
    },

    async handleRead(item) {
      if (item.seen) {
        await markAsRead(item.id)
        const index = this.notifications.findIndex((el) => el.id === item.id)

        if (index >= 0) this.notifications[index].seen = item.seen
        if (this.unreadedCount >= 0) this.unreadedCount += item.seen ? -1 : 1
      } else {
        await markAsUnread(item.id)
        const index = this.notifications.findIndex((el) => el.id === item.id)

        if (index >= 0) this.notifications[index].seen = item.seen
        if (this.unreadedCount >= 0) this.unreadedCount += item.seen ? -1 : 1
      }
    },

    handleScroll() {
      if (this.notifications.length >= this.paginationNotification.total) return

      this.paginationNotification.offset =
        this.paginationNotification.offset + this.paginationNotification.limit

      this.getNotifications(
        {
          limit: this.paginationNotification.limit,
          offset: this.paginationNotification.offset,
        },
        true
      )
    },

    async getNotifications(params = {}, infinity = false) {
      if (this.loading) return

      this.loading = true

      const payload = {
        ...params,
        mine: true,
        sortOrder: 'desc',
        sortBy: 'sentAt',
      }

      const { data, headers } = await getNotifications(payload)

      const notifications = handleDefaultNotificationDataToItems(data)

      this.unreadedCount = parseInt(headers['x-unseen-count']) || 0
      this.paginationNotification.total = parseInt(headers['x-count']) || 0

      this.notifications = infinity
        ? this.notifications.concat(notifications)
        : notifications

      this.loading = false
    },

    handleNotificationContentClick(event) {
      const notification = event.item

      if (notification.module === 'rewards') {
        return this.$router.push(
          { name: 'MeMuralPage' },
          () => {},
          () => {
            if (
              this.$router.getRoutes().some((el) => el.name === 'MeMuralPage')
            )
              return

            window.open('/rewards/mural/me', '_blank')
          }
        )
      }

      if (notification.module === 'performance') {
        const hasEntityToRedirect = !!notification?.data?.entity

        if (!hasEntityToRedirect) return

        return window.open(
          `/performance/okrs/treeview/entity/${notification.data.entity.code}`,
          '_blank'
        )
      }

      if (notification.module === 'growth') {
        const feedbackID = notification.data.feedbackID

        return window.open(
          `/growth?redirect=feedbacks&id=${feedbackID}&action=${notification.action}`,
          '_blank'
        )
      }
    },
  },
}
</script>

<style src="./style.scss" lang="scss"></style>
