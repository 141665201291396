var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"management-group-from-meta-form",attrs:{"data-test-management-group-from-meta-form":""}},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"form-field"},[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"form-field-loading",attrs:{"type":"card"}}):_vm._e(),(!_vm.loading)?_c('CustomInput',{ref:"title",attrs:{"current-value":_vm.form.title,"mandatory":true,"view-mode":_vm._editionMode,"label":_vm.$t('PerformancePage.groupFromMetaForm.fieldTitle'),"readonly":_vm._createMode ? !_vm._canCreateGroupFromMeta : !_vm._canEditGroupFromMeta},on:{"handleInput":function($event){return _vm.handleInput($event, 'title', 500)}}}):_vm._e()],1),_c('div',{staticClass:"form-field"},[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"form-field-loading",attrs:{"type":"card"}}):_vm._e(),(!_vm.loading)?_c('ItemSelector',{ref:"status",attrs:{"data-test-status-selection":"","input-config":{
          label: _vm.$t('PerformancePage.groupFromMetaForm.fieldStatus'),
          showAvatar: false,
          closeOnSelect: true,
          subtleMode: _vm._editionMode,
          rules: _vm._rules,
        },"current-value":{
          data: _vm._currentStatus,
          origin: 'status',
        },"menu-config":{
          attach: true,
          showTabs: false,
        },"menu-options":_vm._statusOptions,"persistent":true,"local-search":true,"watch-current":true,"readonly":_vm._createMode ? !_vm._canCreateGroupFromMeta : !_vm._canEditGroupFromMeta},on:{"update:item":function($event){return _vm.handleInput($event.id, 'inactive')}}}):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }