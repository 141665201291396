<template>
  <Modal
    ref="modal"
    data-test-modal-delete-group-from-meta
    :confirm-button="$t('PerformancePage.deleteModalGroupFromMeta.btnExcluir')"
    :title="$t('PerformancePage.deleteModalGroupFromMeta.title')"
    :has-buttons="true"
    width="35rem"
    @confirmed="handleConfirm"
  >
    <template #no-tab>
      <div class="message">
        {{ $t('PerformancePage.deleteModalGroupFromMeta.subtitle') }}
        {{ groupSelected ? groupSelected?.title?.label : '' }}

        <br />
        <br />

        <span class="highlight">{{
          $t('PerformancePage.deleteModalGroupFromMeta.rememberLabel')
        }}</span>
        {{ $t('PerformancePage.deleteModalGroupFromMeta.highlight') }}
      </div>
    </template>
  </Modal>
</template>

<script>
export default {
  name: 'DeleteModalGroupFromMeta',

  data() {
    return {
      loading: false,
      groupSelected: null,
    }
  },

  methods: {
    handleConfirm() {
      this.$emit('handle-delete', this.groupSelected.id)
      this.handleModal()
    },

    handleOpen(data) {
      this.groupSelected = data
      this.handleModal()
    },

    handleModal() {
      this.$refs.modal.handleDialog()
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
