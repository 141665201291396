import { decodeHTML } from '@/helpers/strings/html'

export function handleDefaultNotificationDataToItems(data) {
  return data.map((el) => {
    const data = el.data
    if (data && data?.invitation) {
      data.invitation = {
        ...data.invitation,
        message: decodeHTML(data.invitation.message),
      }
    }

    return {
      ...el,
      displayDate: el.sentAt,
      description: decodeHTML(el.description),
      data: data,
    }
  })
}
