<template>
  <div
    class="management-group-from-meta-form"
    data-test-management-group-from-meta-form
  >
    <v-form ref="form" v-model="valid">
      <div class="form-field">
        <v-skeleton-loader
          v-if="loading"
          type="card"
          class="form-field-loading"
        ></v-skeleton-loader>

        <CustomInput
          v-if="!loading"
          ref="title"
          :current-value="form.title"
          :mandatory="true"
          :view-mode="_editionMode"
          :label="$t('PerformancePage.groupFromMetaForm.fieldTitle')"
          :readonly="
            _createMode ? !_canCreateGroupFromMeta : !_canEditGroupFromMeta
          "
          @handleInput="handleInput($event, 'title', 500)"
        ></CustomInput>
      </div>

      <div class="form-field">
        <v-skeleton-loader
          v-if="loading"
          type="card"
          class="form-field-loading"
        ></v-skeleton-loader>

        <ItemSelector
          v-if="!loading"
          ref="status"
          data-test-status-selection
          :input-config="{
            label: $t('PerformancePage.groupFromMetaForm.fieldStatus'),
            showAvatar: false,
            closeOnSelect: true,
            subtleMode: _editionMode,
            rules: _rules,
          }"
          :current-value="{
            data: _currentStatus,
            origin: 'status',
          }"
          :menu-config="{
            attach: true,
            showTabs: false,
          }"
          :menu-options="_statusOptions"
          :persistent="true"
          :local-search="true"
          :watch-current="true"
          :readonly="
            _createMode ? !_canCreateGroupFromMeta : !_canEditGroupFromMeta
          "
          @update:item="handleInput($event.id, 'inactive')"
        ></ItemSelector>
      </div>
    </v-form>
  </div>
</template>

<script>
import {
  postGoalGroupsMeta,
  putGoalGroupsMeta,
  getGoalGroupsMetaByID,
} from '@/services/groups-meta'

import { alertErrorBus, alertSuccessBus } from '@/helpers/alerts'

import {
  management_performance_goalgroup_create,
  management_performance_goalgroup_edit,
} from '@/helpers/ability'

export default {
  name: 'ManagementGroupFromMetaForm',

  components: {},

  data() {
    return {
      loading: false,
      valid: false,
      groupMetaID: null,
      form: {
        title: '',
        description: '',
        inactive: false,
        groupEntities: [],
      },
      statusOptions: [
        {
          id: false,
          label: this.$t(
            'PerformancePage.managementGroupFromMeta.status.active'
          ),
        },
        {
          id: true,
          label: this.$t(
            'PerformancePage.managementGroupFromMeta.status.inactive'
          ),
        },
      ],
    }
  },

  computed: {
    _editionMode() {
      return this.$route.name === 'EditGroupFromMeta' && !!this.groupMetaID
    },
    _createMode() {
      return this.$route.name === 'NewGroupFromMeta'
    },
    _canCreateGroupFromMeta() {
      return this.$can('access', management_performance_goalgroup_create)
    },
    _canEditGroupFromMeta() {
      return this.$can('access', management_performance_goalgroup_edit)
    },
    _currentStatus() {
      if (this.groupMetaID) {
        const haveStatusLabel = this.statusOptions.find(
          status => status.id === this.form?.inactive
        )?.label

        if (haveStatusLabel) {
          return [
            {
              id: this.form.inactive,
              label: haveStatusLabel,
            },
          ]
        }

        return null
      }

      return [
        {
          id: this.statusOptions[0].id,
          label: this.statusOptions[0].label,
        },
      ]
    },
    _statusOptions() {
      return [
        {
          value: 'status',
          label: this.$t('PerformancePage.groupFromMetaForm.fieldStatus'),
          type: 'listview',
          items: this.statusOptions,
        },
      ]
    },
    _rules() {
      return [
        v => (!!v && v.length > 0) || this.$t('CustomInput.requiredField'),
      ]
    },
  },

  beforeMount() {
    this.groupMetaID = this.$route.params.id || null
    if (this.groupMetaID) this.startData()
  },

  methods: {
    async startData() {
      try {
        this.loading = true
        const { data } = await getGoalGroupsMetaByID(this.groupMetaID)
        this.form = {
          ...data,
          inactive:
            data.inactive !== undefined && data.inactive !== null
              ? data.inactive
              : null,
        }
        this.loading = false
      } catch (error) {
        alertErrorBus(
          this.$t('PerformancePage.groupFromMetaForm.alert.getByIDError'),
          error
        )
      }
    },
    handleInput(event, field, delay = 0) {
      if (field === 'title') {
        if (event.trim() === '') return
        this.form.description = event
      }

      this.form[field] = event

      if (this._editionMode) {
        if (this.interval) clearInterval(this.interval)

        this.interval = setTimeout(() => {
          this.submit()
        }, delay)
      }
    },
    submit() {
      if (!this.validate()) return

      if (this._createMode) this.createGroupFromMeta()
      if (this._editionMode) this.editGroupFromMeta()
    },
    async editGroupFromMeta() {
      const payload = this.form
      delete payload.id

      await putGoalGroupsMeta(this.groupMetaID, payload)
        .then(() => {
          alertSuccessBus(
            this.$t('PerformancePage.groupFromMetaForm.alert.updateSuccess')
          )
          this.$root.$emit('handle-group-from-meta')
        })
        .catch(error => {
          alertErrorBus(
            this.$t('PerformancePage.groupFromMetaForm.alert.updateError'),
            error
          )
        })
    },
    async createGroupFromMeta() {
      const payload = this.form

      await postGoalGroupsMeta(payload)
        .then(() => {
          alertSuccessBus(
            this.$t('PerformancePage.groupFromMetaForm.alert.createSuccess')
          )
          this.$root.$emit('handle-group-from-meta')
          this.$emit('close')
        })
        .catch(error => {
          alertErrorBus(
            this.$t('PerformancePage.groupFromMetaForm.alert.createError'),
            error
          )
        })
    },
    validateCustomInputReturn(result) {
      if (this.form.title.trim() === '') return false

      const isInvalid = result instanceof Node
      if (isInvalid) {
        return false
      }

      return result === undefined
    },
    validate() {
      const forms = ['form']
      let valid = true

      let customInputReturn = this.validateCustomInputReturn(
        this.$refs['title'].validate()
      )

      forms.forEach(e => {
        const value = this.$refs[e].validate()
        if (!value && value != undefined) valid = false
      })

      if (valid && !customInputReturn) valid = false

      if (!valid) {
        const erros = this.$el.getElementsByClassName('error--text')

        setTimeout(() => {
          const el = erros[0]
          if (el) el.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }, 0)
      }

      return valid
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
