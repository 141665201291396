<template>
  <div v-if="loading.content" class="sidebar">
    <v-skeleton-loader
      class="mb-6"
      type="image"
      height="96"
    ></v-skeleton-loader>

    <v-skeleton-loader class="mb-6" type="image"></v-skeleton-loader>

    <v-row class="mb-6">
      <v-col v-for="n in 6" :key="n" cols="6">
        <v-skeleton-loader
          type="list-item-two-line"
          max-height="54px"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-skeleton-loader type="table-tbody" class="mb-6"></v-skeleton-loader>

    <v-skeleton-loader
      v-for="n in 6"
      :key="n"
      type="list-item-two-line"
      max-height="54px"
    ></v-skeleton-loader>
  </div>

  <v-form v-else ref="form" v-model="valid" class="sidebar">
    <div class="group-form">
      <div class="header-data">
        <div class="horizontal-inputs-add mt-4 mb-6">
          <div class="container-add">
            <UploadIMG
              :link-image="group.image"
              :readonly="!(_canEditName || _canEditGroups)"
              @handleInput="handleInput($event, 'image')"
            ></UploadIMG>
          </div>

          <div class="grid-input-size">
            <CustomInput
              data-test-view-group-form-input="name"
              class="name-input"
              :current-value="group.name"
              :mandatory="true"
              :view-mode="true"
              :readonly="!(_canEditName || _canEditGroups)"
              @handleInput="handleInput($event, 'name')"
            ></CustomInput>
          </div>

          <div class="chip-content">
            <div class="label-status">Status</div>

            <v-chip
              data-test-view-group-status
              :class="`custom-chip custom-chip-${
                form.inactive ? 'inactive' : 'active'
              }`"
            >
              {{
                $t(
                  `PeoplePage.viewPerson.about.status.${
                    form.inactive ? 'inactive' : 'active'
                  }`
                )
              }}
            </v-chip>
          </div>
        </div>

        <div class="horizontal-inputs-add">
          <CustomInput
            data-test-view-group-form-input="code"
            :label="$t('GroupsPage.viewGroup.about.costCenter')"
            :current-value="group.costCenter ? group.costCenter : ''"
            :mandatory="true"
            :view-mode="true"
            :readonly="!(_canEditCostCenter || _canEditGroups)"
            @handleInput="handleInput($event, 'costCenter')"
          ></CustomInput>
        </div>
      </div>

      <DetailSectionAccordion
        id="accordion-Add-Information-1"
        data-test-view-group-form-subgroups
        type="subgrupo"
        :title="$t('GroupsPage.viewGroup.subgroups.title')"
        :opened="true"
      >
        <DataTable
          ref="subgroupTable"
          class="data-table-margin-resized"
          :title="$t('GroupsPage.viewGroup.subgroups.title').toLowerCase()"
          :items="
            subgroups.filteredItems.length > 0
              ? subgroups.filteredItems
              : subgroups.items
          "
          :headers="_subgroupsHeaders"
          :add-options="subgroups.options"
          :empty-placeholder="
            $t('GroupsPage.viewGroup.subgroups.dataTable.empty')
          "
          :unique-selection-options="true"
          :local-add-search="true"
          :hide-header="true"
          :hide-option="true"
          :attach="true"
          :content-style="false"
          :hide-add-elements="_hideSubgroups"
          :items-per-page="7"
          :roles-readonly="!(_canEditTableSubgroups || _canEditGroups)"
          @add-data="addSubgroup($event)"
          @delete="removeSubgroup($event)"
          @search="searchSubgroup($event)"
          @focus:input="fetchGroupsTree()"
        ></DataTable>
      </DetailSectionAccordion>

      <DetailSectionAccordion
        id="accordion-Add-Information-2"
        data-test-view-group-form-people
        type="people"
        :title="$t('GroupsPage.viewGroup.people.title')"
        :opened="true"
      >
        <DataTable
          ref="peopleTable"
          class="data-table-margin-resized"
          :title="$t('GroupsPage.viewGroup.people.title').toLowerCase()"
          :items="
            peopleGroup.filteredItems.length > 0
              ? peopleGroup.filteredItems
              : peopleGroup.items
          "
          :selection-options="peopleGroup.selectionOptions"
          :headers="_peopleHeaders"
          :add-options="peopleGroup.options"
          :empty-placeholder="$t('GroupsPage.viewGroup.people.dataTable.empty')"
          :unique-selection-options="true"
          :hide-header="true"
          :hide-option="true"
          :attach="true"
          :content-style="false"
          :hide-add-elements="peopleGroup.items.map(el => el.id)"
          :items-per-page="7"
          :roles-readonly="!(_canEditTablePeople || _canEditGroups)"
          @add-data="addPerson($event)"
          @delete="removePerson($event)"
          @search="searchPerson($event)"
          @update-data="updatePerson($event)"
          @focus:input="getPeople()"
          @search:item="getPeople($event)"
        >
          <template #filter>
            <FilterSelection
              :show="rolesFilterOpened"
              :options="peopleGroup.selectionOptions"
              @handleVision="handleRolesFilterVision"
              @handleOptionChange="handleRoleOptionChange"
            >
              <div class="filter-roles" @click="handleRolesFilterMenu">
                <i class="fi fi-rr-filter mr-1 filter-roles-icon-mt"></i>
                <span> {{ _filterTitle }}</span>
                <i
                  class="ml-1 filter-roles-icon-mt"
                  :class="_rolesFilterIcon"
                ></i>
              </div>
            </FilterSelection>
          </template>
        </DataTable>
      </DetailSectionAccordion>

      <DetailSectionAccordion
        id="accordion-Add-Information-3"
        data-test-view-group-form-hierarchy
        type="tree"
        :title="$t('GroupsPage.viewGroup.hierarchy.title')"
        :text-button="$t('GroupsPage.viewGroup.hierarchy.linkHierarchy')"
        :has-button="hierarchy.length > 1 && (_canEditGroups || _canMoveGroups)"
        :opened="true"
        @headerClick="handleLinkHierarchy()"
      >
        <HierarchyBetweenElements
          v-if="hierarchy.length > 1"
          :nodes="hierarchy"
          :has-bar="true"
          :has-remove="false"
          :has-clicked="true"
          @titleClicked="$emit('edit-node', $event)"
        ></HierarchyBetweenElements>

        <div v-else class="hierarchy">
          <div class="label">
            {{ $t('GroupsPage.viewGroup.hierarchy.empty.message') }}
          </div>

          <div
            v-if="_canEditGroups || _canMoveGroups"
            class="linkHierarchy"
            @click="handleLinkHierarchy()"
          >
            <v-icon class="linkHierarchy" x-small left> fi fi-br-plus</v-icon>

            <span class="label-link">
              {{ $t('GroupsPage.viewGroup.hierarchy.empty.link') }}
            </span>
          </div>
        </div>
      </DetailSectionAccordion>
    </div>

    <ModalInactiveGroup
      ref="inactiveDialog"
      @confirm:group-only="statusSubmit(false, false)"
      @confirm:group-people="statusSubmit(false, true)"
    ></ModalInactiveGroup>

    <ModalMoveSubgroup
      ref="modalMoveSubgroup"
      :subgroup-move-name="subgroupMoveName"
      @cancel="handleMoveSubgroupModal()"
      @confirm="confirmMoveSubgroup()"
    ></ModalMoveSubgroup>

    <InsertModal
      ref="insertModal"
      :ignored-nodes="subgroups?.items"
      @linkSelected="setParentGroup($event)"
    ></InsertModal>
  </v-form>
</template>

<script>
import {
  getGroup,
  updateGroup,
  getGroupSubgroups,
  addSubgroup,
  removeSubgroup,
  deleteGroup,
  getGroupsTree,
  getGroupPeople,
  removePersonGroup,
  updatePersonGroup,
  getHierarchyById,
  changeGroupStatus,
} from '@/services/groups'
import { getGroupsRoles } from '@/services/papers'
import {
  management_groups_edit,
  management_groups_edit_name,
  management_groups_edit_costCenter,
  management_groups_edit_people,
  management_groups_edit_subgroups,
  management_groups_move,
} from '@/helpers/ability'
import { getPeople } from '@/services/people'

import UploadIMG from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/PeoplePage/Views/Partials/UploadIMG/UploadIMG.vue'
import HierarchyBetweenElements from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/GroupsPage/parts/HierarchyBetweenElements/HierarchyBetweenElements.vue'
import FilterSelection from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Partials/FilterSelection/FilterSelection.vue'
import DataTable from '@/components/DataTable/DataTable.vue'
import InsertModal from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/GroupsPage/parts/InsertModal/InsertModal.vue'

import ModalInactiveGroup from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/GroupsPage/parts/ModalInactiveGroup/ModalInactiveGroup.vue'
import ModalMoveSubgroup from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/GroupsPage/parts/ModalMoveSubgroup/ModalMoveSubgroup.vue'

import _colors from '@/styles/abstracts/_colors.module.scss'

import _ from 'lodash'

export default {
  name: 'ViewGroup',
  components: {
    UploadIMG,
    HierarchyBetweenElements,
    FilterSelection,
    DataTable,
    InsertModal,
    ModalInactiveGroup,
    ModalMoveSubgroup,
  },

  inject: ['configSideQuickRegister'],

  data() {
    return {
      interval: null,
      valid: false,
      loading: {
        content: true,
      },
      group: null,
      groups: [],
      people: [],
      groupRoles: {
        engagement: [],
        growth: [],
        performance: [],
        management: [],
      },
      subgroups: {
        items: [],
        filteredItems: [],
        options: [
          {
            value: 'subgroup',
            label: this.$t('PeoplePage.newPerson.groups.addGroup'),
            type: 'treeview',
            items: [],
          },
        ],
        selectionOptions: [],
      },
      peopleGroup: {
        items: [],
        filteredItems: [],
        options: [
          {
            value: 'people',
            label: this.$t('GroupsPage.accordion.people.addLabel'),
            type: 'listview',
            items: [],
          },
        ],
        selectionOptions: [],
      },
      hierarchy: [],
      form: {
        image: '',
        name: '',
        costCenter: '',
        parentGroupID: '',
        inactive: false,
      },
      rolesFilterOpened: false,
      countRolesAdded: 0,
      rolesSelected: [[], [], []],
      subgroupMoveName: '',
    }
  },

  computed: {
    _hideSubgroups() {
      const hideList = []
      hideList.push(...this.subgroups.items.map(el => el.id))

      if (this.group && this.group.id) hideList.push(this.group.id)

      return hideList
    },
    _canEditGroups() {
      return this.$can('access', management_groups_edit)
    },
    _canEditName() {
      return this.$can('access', management_groups_edit_name)
    },
    _canEditCostCenter() {
      return this.$can('access', management_groups_edit_costCenter)
    },
    _canEditTablePeople() {
      return this.$can('access', management_groups_edit_people)
    },
    _canEditTableSubgroups() {
      return this.$can('access', management_groups_edit_subgroups)
    },
    _canMoveGroups() {
      return this.$can('access', management_groups_move)
    },
    _subgroupsHeaders() {
      return [
        {
          text: this.$t(
            'GroupsPage.viewGroup.subgroups.dataTable.headers.subgroup'
          ),
          value: 'subgroup',
          type: 'entity-item',
        },
        {
          text: this.$t(
            'GroupsPage.viewGroup.subgroups.dataTable.headers.people'
          ),
          value: 'people',
          type: 'chip-group',
          align: 'start',
        },
        {
          text: this.$t(
            'GroupsPage.viewGroup.subgroups.dataTable.headers.subgroups'
          ),
          value: 'subgroups',
          type: 'chip-group',
          align: 'start',
        },
        {
          text: this.$t(
            'GroupsPage.viewGroup.subgroups.dataTable.headers.status'
          ),
          value: 'status',
          type: 'text-status',
        },
        {
          text: this.$t(
            'GroupsPage.viewGroup.subgroups.dataTable.headers.actions'
          ),
          value: 'actions',
          type: 'action-buttons',
          align: 'center',
        },
      ]
    },

    _peopleHeaders() {
      return [
        {
          text: this.$t('GroupsPage.viewGroup.people.dataTable.headers.person'),
          value: 'person',
          type: 'entity-item',
        },
        {
          text: this.$t(
            'GroupsPage.viewGroup.people.dataTable.headers.engagement'
          ),
          value: 'engagement',
          type: 'combo-selection',
          options: this.groupRoles.engagement || [],
        },
        {
          text: this.$t('GroupsPage.viewGroup.people.dataTable.headers.growth'),
          value: 'growth',
          type: 'combo-selection',
          options: this.groupRoles.growth || [],
        },
        {
          text: this.$t(
            'GroupsPage.viewGroup.people.dataTable.headers.performance'
          ),
          value: 'performance',
          type: 'combo-selection',
          options: this.groupRoles.performance || [],
        },
        {
          text: this.$t('GroupsPage.viewGroup.people.dataTable.headers.status'),
          value: 'status',
          type: 'text-status',
        },
        {
          text: this.$t(
            'GroupsPage.viewGroup.people.dataTable.headers.actions'
          ),
          value: 'actions',
          type: 'action-buttons',
          align: 'center',
        },
      ]
    },

    _filterTitle() {
      if (this.countRolesAdded === 1) {
        return this.$t('GroupsPage.accordion.people.filterRole', {
          num: this.countRolesAdded,
        })
      }
      if (this.countRolesAdded > 1) {
        return this.$t('GroupsPage.accordion.people.filterNRoles', {
          num: this.countRolesAdded,
        })
      }
      return this.$t('GroupsPage.accordion.people.filterAllRoles')
    },

    _rolesFilterIcon() {
      if (this.rolesFilterOpened) {
        return 'fi-br-caret-up'
      }
      return 'fi-br-caret-down'
    },
  },

  watch: {
    'form.parentGroupID': function (parentGroupID) {
      this.fetchHierarchy(parentGroupID)
    },
    peopleGroup: {
      handler() {
        if (!this.peopleGroup?.items?.length || !this.group) return false

        const newPeopleCount = this.peopleGroup?.items?.filter(people => {
          const hasPerformancePermissions = people.performance.length
          const hasEngagementPermissions = people.engagement.length
          const hasManagementPermissions = people.management.length
          const hasGrowthPermissions = people.growth.length

          return (
            hasPerformancePermissions ||
            hasEngagementPermissions ||
            hasManagementPermissions ||
            hasGrowthPermissions
          )
        }).length

        this.$emit('updated-people', this.group.id, newPeopleCount)
      },
      deep: true,
    },
    rolesFilterOpened(newValue) {
      if (!newValue) {
        this.filterPeople(this.rolesSelected, this.countRolesAdded)
        this.filterSubgroupPeople(this.rolesSelected, this.countRolesAdded)
      }
    },
  },

  mounted() {
    this.getGroup()
    this.getGroupSubgroups()
    this.getGroupRoles()
    this.getGroupPeople()
    this.fetchGroupsTree()
    this.fetchGroupRoles()
  },

  methods: {
    async fetchGroupRoles() {
      const payload = {
        limit: 500,
        offset: 0,
      }

      const { data } = await getGroupsRoles(payload)
      this.peopleGroup.selectionOptions[0] = data.engagement
      this.peopleGroup.selectionOptions[1] = data.growth
      this.peopleGroup.selectionOptions[2] = data.performance

      this.subgroups.selectionOptions[0] = data.engagement
      this.subgroups.selectionOptions[1] = data.growth
      this.subgroups.selectionOptions[2] = data.performance
    },
    getColumnActionSubgroup() {
      let action = []
      if (this._canEditTableSubgroups || this._canEditGroups) {
        action = ['delete']
      }
      return action
    },
    getColumnActionPeople() {
      let action = []
      if (this._canEditTablePeople || this._canEditGroups) {
        action = ['delete']
      }
      return action
    },
    async submit() {
      try {
        const payload = {
          groupID: this.group.id,
          body: {
            name: this.form.name,
            image: this.form.image,
            costCenter: this.form.costCenter,
            inactive: this.form.inactive,
            parentGroupID: this.form.parentGroupID,
          },
        }

        await updateGroup(payload).then(res => {
          this.$emit('success-update', res.data)

          if (this.group.parentGroupID !== this.form.parentGroupID) {
            this.$emit('success-move', res.data)
          }

          if (this.hierarchy.length > 0) {
            const nodeUpdated = {
              ...this.hierarchy[this.hierarchy.length - 1],
              image: res.data.image,
              name: res.data.name,
              costCenter: res.data.costCenter,
              inactive: res.data.inactive,
            }

            this.hierarchy.splice(this.hierarchy.length - 1, 1, nodeUpdated)
          }

          this.handleGroup(res.data)

          this.displayAlert(
            'success',
            this.$t('GroupsPage.viewGroup.successUpdated')
          )
        })
      } catch (err) {
        this.displayAlert(
          'error',
          err?.response?.data?.validation?.message ||
            err?.response?.data?.message
        )
      }
    },

    async statusSubmit(activate, people) {
      const options = {
        people: people,
        hierarchy: false,
      }

      await changeGroupStatus(this.group.id, activate, options).then(() => {
        if (!activate) this.$refs.inactiveDialog.handleDialog()
        this.form.inactive = !activate
        this.$emit('success-update', {
          ...this.form,
          id: this.group.id,
        })

        this.configSideQuickRegister('viewGroup', {
          id: this.group.id,
          inactive: this.form.inactive,
        })
      })
    },

    async deleteGroup() {
      await deleteGroup(this.$route.params.groupId)
        .then(() => {
          this.displayAlert(
            'success',
            this.$t('GroupsPage.viewGroup.successDelete')
          )

          this.$emit('close')
        })
        .catch(err => {
          this.displayAlert(
            'error',
            err?.response?.data?.validation?.message ||
              err?.response?.data?.message
          )
        })
    },

    async getGroup() {
      await getGroup(this.$route.params.groupId)
        .then(res => {
          this.handleGroup(res.data)
        })
        .catch(() => {
          this.$emit('close')
        })
        .finally(() => {
          this.loading.content = false
        })
    },

    async getGroupSubgroups() {
      await getGroupSubgroups(this.$route.params.groupId).then(res => {
        if (!res || !res.data || !res.data.length) return

        this.subgroups.items = res.data.reverse().map(el => {
          el.peopleCount || (el.peopleCount = 0)
          el.childrenCount || (el.childrenCount = 0)

          return {
            id: el.id,
            subgroup: {
              image: el.image,
              title: el.name,
            },
            people: [
              el.peopleCount > 0
                ? el.peopleCount +
                  ` ${el.peopleCount > 1 ? 'pessoas' : 'pessoa'}`
                : '-',
            ],
            subgroups: [
              el.childrenCount > 0
                ? el.childrenCount +
                  ` ${el.childrenCount > 1 ? 'subgrupos' : 'subgrupo'}`
                : '-',
            ],
            status: {
              label: el.inactive ? 'Inativo' : 'Ativo',
              status: el.inactive ? 'inactive' : 'success',
            },
            actions: this.getColumnActionSubgroup(),
          }
        })
      })
    },

    async getGroupPeople() {
      await getGroupPeople(this.$route.params.groupId).then(res => {
        this.peopleGroup.items = res.data.map(el => {
          return {
            id: el.person.id,
            person: {
              image: el.person.photo,
              title: el.person.name,
            },
            engagement: (el.engagement || []).map(role => ({ id: role.id })),
            performance: (el.performance || []).map(role => ({ id: role.id })),
            growth: (el.growth || []).map(role => ({ id: role.id })),
            management: (el.management || []).map(role => ({ id: role.id })),
            status: {
              label: this.$t(
                `GroupsPage.viewGroup.about.status.${el.person.status}`
              ),
              status: el.person.status,
            },
            actions: this.getColumnActionPeople(),
          }
        })
      })
    },

    async getGroupRoles() {
      const payload = {
        limit: 500,
        offset: 0,
      }

      await getGroupsRoles(payload).then(res => {
        this.groupRoles = res.data
      })
    },

    async fetchGroupsTree() {
      const { data } = await getGroupsTree()

      this.subgroups.options[0].items = data.filter(e => e.id)
    },

    async getPeople(search) {
      const payload = {
        limit: 30,
        offset: 0,
        showInactive: true,
        ...(search && { name: search }),
      }

      let { data } = await getPeople(payload)

      const statusMap = {
        confirmed: {
          label: this.$t('PeoplePage.confirmedLabel'),
          color: _colors.greenColor,
        },
        invited: {
          label: this.$t('PeoplePage.guestLabel'),
          color: _colors.secondary,
        },
        inactive: {
          label: this.$t('PeoplePage.inactiveLabel'),
          color: _colors.neutral5,
        },
      }

      data = data.map(people => {
        const statusInfo = statusMap[people.status] || {
          label: '--',
          color: '',
        }

        return {
          ...people,
          label: people.name,
          inactive: people.status === 'inactive',
          name: {
            image: people.photo,
            title: people.name,
            subtitle: people.email,
          },
          access: {
            id: people.status,
            ...statusInfo,
            status: people.status,
          },
        }
      })
      this.peopleGroup.options[0].items = data
      this.subgroups.options[0].items = data

      this.people = data
    },

    handleGroup(data) {
      this.group = data
      this.form.name = data.name
      this.form.image = data.image
      this.form.costCenter = data.costCenter
      this.form.inactive = data.inactive
      this.form.parentGroupID = data.parentGroupID
    },

    handleInput(value, inputName) {
      if (this.interval) clearInterval(this.interval)
      const currentValue = this.form[inputName]

      if (_.isEqual(currentValue, value)) return
      this.interval = setTimeout(() => {
        this.form[inputName] = structuredClone(value)
        this.submit()
      }, 800)
    },

    changeStatus(value) {
      if (value) return this.statusSubmit(true, false)

      this.$refs.inactiveDialog.handleDialog()
    },

    async addSubgroup(event) {
      if (this.subgroups.items.some(item => item.id === event.id)) return

      this.subgroupTemp = event

      if (event.parentGroupID != null) {
        this.subgroupMoveName = event.label
        this.$refs.modalMoveSubgroup.handleDialog()
      } else {
        await this.insertSubgroup(event)
      }
    },

    async removeSubgroup(event) {
      const index = this.subgroups.items.findIndex(el => el.id === event.id)
      this.subgroups.items.splice(index, 1)

      if (this.subgroups.filteredItems.length > 0) {
        const index = this.subgroups.filteredItems.findIndex(
          el => el.id === event.id
        )
        this.subgroups.filteredItems.splice(index, 1)

        if (this.subgroups.filteredItems.length === 0)
          this.$refs.subgroupsTable.clearSearch()
      }

      const payload = {
        groupID: this.$route.params.groupId,
        subgroupID: event.id,
      }

      await removeSubgroup(payload)
        .then(() => {
          const showAlert = false
          this.$emit(
            'updated-subgroups',
            'remove',
            this.group.id,
            event.id,
            showAlert
          )

          this.displayAlert(
            'success',
            this.$t('GroupsPage.viewGroup.successUpdated')
          )
        })
        .catch(err => {
          this.displayAlert(
            'error',
            err?.response?.data?.validation?.message ||
              err?.response?.data?.message
          )
        })
    },

    searchSubgroup(event) {
      const array = []

      if (event) {
        this.subgroups.items.map(el => {
          if (
            el.subgroup.title.toLowerCase().includes(event.name.toLowerCase())
          )
            array.push(el)
        })
      }

      this.subgroups.filteredItems = array
    },

    addPerson(event) {
      const person = {
        id: event.id,
        person: {
          image: event.image,
          title: event.label,
        },
        engagement: [],
        growth: [],
        performance: [],
        management: [],
        status: {
          label: this.$t(`GroupsPage.viewGroup.about.status.${event.status}`),
          status: event.status,
        },
        actions: ['delete'],
      }

      this.peopleGroup.items.unshift(person)
    },

    async removePerson(event) {
      const index = this.peopleGroup.items.findIndex(el => el.id === event.id)
      this.peopleGroup.items.splice(index, 1)

      if (this.peopleGroup.filteredItems.length > 0) {
        const index = this.peopleGroup.filteredItems.findIndex(
          el => el.id === event.id
        )
        this.peopleGroup.filteredItems.splice(index, 1)

        if (this.peopleGroup.filteredItems.length === 0)
          this.$refs.peopleTable.clearSearch()
      }

      const payload = {
        groupID: this.$route.params.groupId,
        personID: event.id,
      }

      await removePersonGroup(payload)
        .then(() => {
          this.displayAlert(
            'success',
            this.$t('GroupsPage.viewGroup.successUpdated')
          )
        })
        .catch(err => {
          this.displayAlert(
            'error',
            err?.response?.data?.validation?.message ||
              err?.response?.data?.message
          )
        })
    },

    async updatePerson(event) {
      this.peopleGroup.items.map(item => {
        const moduleName = {
          0: 'engagement',
          1: 'growth',
          2: 'performance',
        }[event.index]
        if (event.id === item.id) {
          item[moduleName] = event.values
        }
        return item
      })
      this.peopleGroup.filteredItems.map(item => {
        const moduleName = {
          0: 'engagement',
          1: 'growth',
          2: 'performance',
        }[event.index]
        if (event.id === item.id) {
          item[moduleName] = event.values
        }
        return item
      })

      const index = this.peopleGroup.items.findIndex(el => el.id === event.id)

      this.peopleGroup.items[index][event.key] = event.values

      if (this.peopleGroup.filteredItems.length > 0) {
        const index = this.peopleGroup.filteredItems.findIndex(
          el => el.id === event.id
        )
        this.peopleGroup.filteredItems[index][event.key] = event.values
      }

      const person = this.peopleGroup.items.find(el => el.id === event.id)

      if (!person) return

      const payload = {
        groupID: this.group.id,
        personID: event.id,
        body: {
          engagement: person.engagement || [],
          growth: person.growth || [],
          performance: person.performance || [],
          management: person.management || [],
        },
      }

      await updatePersonGroup(payload)
        .then(() => {
          this.displayAlert(
            'success',
            this.$t('GroupsPage.viewGroup.successUpdated')
          )
        })
        .catch(err => {
          this.displayAlert(
            'error',
            err?.response?.data?.validation?.message ||
              err?.response?.data?.message
          )
        })
    },

    async searchPerson(search) {
      if (!search) return
      if (search.name === '') {
        this.peopleGroup.filteredItems = this.peopleGroup.items
      } else {
        this.peopleGroup.filteredItems = this.peopleGroup.items.filter(item => {
          return item.person.title
            .toLowerCase()
            .includes(search.name.toLowerCase())
        })
      }
    },

    handleLinkHierarchy() {
      this.$refs.insertModal.toggleModal(true)
    },

    setParentGroup(parentGroup) {
      this.form.parentGroupID = parentGroup.id
      this.submit()
    },

    fetchHierarchy(parentGroupID) {
      if (!parentGroupID) return (this.hierarchy = [])

      getHierarchyById(parentGroupID, { flat: true }).then(({ data }) => {
        if (!data || !data.length) return (this.hierarchy = [])

        const group = {}
        Object.keys(data[0]).forEach(key => (group[key] = this.group[key]))
        data.push(group)

        this.hierarchy = data
      })
    },

    handleRoleOptionChange(role, id) {
      if (this.rolesSelected[id].includes(role)) {
        this.countRolesAdded--
        this.rolesSelected[id] = this.rolesSelected[id].filter(
          item => item.id !== role.id
        )
      } else {
        this.countRolesAdded++
        this.rolesSelected[id].push(role)
      }
    },

    handleRolesFilterVision(value) {
      this.rolesFilterOpened = value
    },

    handleRolesFilterMenu() {
      this.rolesFilterOpened = !this.rolesFilterOpened
    },

    hasAnyRole(item, roles) {
      roles = roles.filter(e => !!e && e.length)
      const found = []
      const moduleName = {
        0: 'engagement',
        1: 'growth',
        2: 'performance',
      }
      roles.forEach((role, id) => {
        const idArray = item[moduleName[id]].map(item => item.id)
        let arr = []
        role.forEach(r => {
          arr.push(idArray.includes(r.id))
        })
        found.push(arr.includes(true))
      })
      return found.includes(true)
    },

    filterPeople(roles, countRolesAdded) {
      const newItems = this.peopleGroup.items.slice(
        0,
        this.peopleGroup.items.length
      )
      if (countRolesAdded === 0) {
        this.peopleGroup.filteredItems = newItems
      } else {
        this.peopleGroup.filteredItems = this.peopleGroup.items.filter(item => {
          return this.hasAnyRole(item, roles)
        })
      }
    },

    filterSubgroupPeople(roles, countRolesAdded) {
      const newItems = this.subgroups.items.slice(
        0,
        this.subgroups.items.length
      )
      if (countRolesAdded === 0) {
        this.subgroups.filteredItems = newItems
      } else {
        this.subgroups.filteredItems = this.subgroups.items.filter(item => {
          return this.hasAnyRole(item, roles)
        })
      }
    },

    handleMoveSubgroupModal() {
      this.$refs.modalMoveSubgroup.handleDialog()
    },

    async confirmMoveSubgroup() {
      await this.insertSubgroup(this.subgroupTemp)
      this.handleMoveSubgroupModal()
    },

    async insertSubgroup(subgroup) {
      const payload = {
        groupID: this.$route.params.groupId,
        subgroupID: subgroup.id,
      }

      await addSubgroup(payload)
        .then(() => {
          this.$emit('updated-subgroups', 'add', this.group.id, payload.id)
          this.getGroupSubgroups()

          this.displayAlert(
            'success',
            this.$t('GroupsPage.viewGroup.successUpdated')
          )
        })
        .catch(err => {
          this.displayAlert(
            'error',
            err?.response?.data?.validation?.message ||
              err?.response?.data?.message
          )
        })
    },

    displayAlert(type, description) {
      this.$emit('display-alert', { type, description })
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
