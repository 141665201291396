<template>
  <LateralMenu data-test-lateral-menu>
    <template #default="{ isMini }">
      <FavoriteFilters
        v-if="_showFavoriteFilters"
        data-test-favorite-filters
        class="menu-item"
        :mini="isMini"
        :loading="favoriteFiltersLoading"
        @select="handleFavoriteSelect"
        @save="handleFavoriteSave"
        @remove="handleFavoriteRemove"
      ></FavoriteFilters>

      <ActionPlansFilter
        v-if="_showActionPlansFilter"
        filter-name="actionPlanFilter"
        :is-mini="isMini"
        :group-i-d="getGroupId"
      ></ActionPlansFilter>

      <OpinionsFilter
        v-if="_showOpinionsFilter"
        filter-name="OpinionsFilter"
        :is-mini="isMini"
      ></OpinionsFilter>

      <MarketSegmentAndTeamSizeFilter
        v-if="_showMarketSegmentAndTeamSizeFilter"
        ref="marketSegmentAndTeamSizeFilter"
        filter-name="marketSegmentAndTeamSizeFilter"
        :is-mini="isMini"
        :hide-market-segment="_hideMarketSegmentField"
      ></MarketSegmentAndTeamSizeFilter>

      <DemographicReports
        v-if="_showDemographicReport"
        class="menu-item"
        :is-mini="isMini"
        :locked="!_canEngagementPageDemographicReports"
        :reports="demographicReports.value"
        :report-selected="demographicReportID"
        :is-loading="demographicReports.isLoading"
        :display-show-more-action="_displayDemographicReportShowMoreAction"
        @on-show-more-click="fetchDemographicReports"
        @on-delete-click="handleReportDelete"
        @on-refresh-click="fetchDemographicReports"
        @on-report-click="handleReportSelect"
      ></DemographicReports>

      <SmartAttributes
        v-if="_showDemographicReport"
        ref="smartAttributes"
        class="menu-item"
        :is-mini="isMini"
        :is-loading="reportAttributes.isLoading"
        :locked="!_canEngagementPageDemographicReports"
        :attributes="reportAttributes.value"
        @on-generate-report="handleGenerateReport"
      ></SmartAttributes>

      <ActionPlans
        v-if="_showActionPlans"
        class="menu-item"
        :is-mini="isMini"
        :group-i-d="getGroupId"
        :action-plans="actionPlans.value"
        :show-loading="actionPlans.isLoading"
        :locked="!_canEngagementActionPlans"
        :current-context="_getCurrentContext"
        :display-show-more-action="_displayActionPlanShowMoreAction"
        @on-show-more-click="fetchActionPlans"
      ></ActionPlans>

      <GroupPeople
        v-if="_showGroupPeople"
        class="menu-item"
        :is-mini="isMini"
        :group-i-d="getGroupId"
      ></GroupPeople>
    </template>
  </LateralMenu>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import FavoriteFilters from '@/pages/EngagementPage/Views/FavoriteFilters/FavoriteFilters.vue'
import ActionPlans from '@/pages/EngagementPage/Views/LateralMenu/Partials/ActionPlans/ActionPlans.vue'
import ActionPlansFilter from '@/pages/EngagementPage/Views/LateralMenu/Partials/ActionPlansFilter/ActionPlansFilter.vue'
import DemographicReports from '@/pages/EngagementPage/Views/LateralMenu/Partials/DemographicReports/DemographicReports.vue'
import GroupPeople from '@/pages/EngagementPage/Views/LateralMenu/Partials/GroupPeople/GroupPeople.vue'
import MarketSegmentAndTeamSizeFilter from '@/pages/EngagementPage/Views/LateralMenu/Partials/MarketSegmentAndTeamSizeFilter/MarketSegmentAndTeamSizeFilter.vue'
import OpinionsFilter from '@/pages/EngagementPage/Views/LateralMenu/Partials/OpinionsFilter/OpinionsFilter.vue'
import SmartAttributes from '@/pages/EngagementPage/Views/LateralMenu/Partials/SmartAttributes/SmartAttributes.vue'

import { listActionPlans } from '@/service/actionPlans'
import {
  createDemographicReport,
  deleteDemographicReport,
  getDemographicReportsList,
} from '@/service/climate'
import {
  createDemographicReport as createDemographicReportCulture,
  deleteDemographicReport as deleteDemographicReportCulture,
  getDemographicReportsList as getDemographicReportsListCulture,
} from '@/service/culture'
import {
  getFavoriteFilters,
  removeFavoriteFilters,
  saveFavoriteFilters,
} from '@/service/favoriteFilters'
import { getAttributesList } from '@/service/people'

import * as _permissions from '@/helpers/ability/permissions'

export default {
  name: 'LateralMenuView',
  components: {
    ActionPlans,
    ActionPlansFilter,
    DemographicReports,
    FavoriteFilters,
    GroupPeople,
    MarketSegmentAndTeamSizeFilter,
    OpinionsFilter,
    SmartAttributes,
  },

  data() {
    return {
      favoriteFiltersLoading: false,

      actionPlans: {
        value: [],
        offset: 0,
        limit: 2,
        isLoading: false,
        noMoreResults: false,
      },

      actionPlansCreatorsList: [],

      favoriteFilters: {
        limit: 499,
        offset: 0,
      },

      demographicReports: {
        value: [],
        offset: 0,
        limit: 3,
        isLoading: false,
        noMoreResults: false,
        reportWillBeUnselect: false,
        reportIsSelected: false,
      },

      reportAttributes: {
        value: [],
        isLoading: false,
      },
    }
  },

  computed: {
    ...mapState(['favorites', 'demographicReportID']),
    ...mapGetters(['getGroupId', 'getIsBasicPlanAdminUser']),

    _getCurrentContext() {
      const contexts = {
        ClimatePage: 'climate',
        CulturePage: 'culture',
        ParticipationPage: 'participation',
        ComparisonPage: 'comparison',
        IndicatorsPage: 'benchmark',
        RankingPage: 'benchmark',
        InternalRankingPage: 'benchmark',
        MyOpinions: 'opinions',
        ListOpinionsPage: 'opinions',
        SentOpinionsPage: 'opinions',
        ReceivedOpinionsPage: 'opinions',
        ReceivedOpinionsListPage: 'opinions',
        AnalyticsReportPage: 'opinions',
        OpinionsAnalyticsReportPage: 'opinions',
        ActionPlansPage: 'action-plan',
        ActionPlanAnalyticsReportPage: 'action-plan',
      }

      return contexts[this.$route.name] || ''
    },

    _canEngagementOpinionsTags() {
      return this.$can('access', _permissions.engagement_opinions_tags)
    },

    _canEngagementActionPlans() {
      return this.$can('access', _permissions.engagement_action_plans)
    },

    _canEngagementPageDemographicReports() {
      const permissionByContext = {
        climate: 'engagement.climate.demographic-reports',
        culture: 'engagement.culture.demographic-reports',
      }

      const permission = permissionByContext[this._getCurrentContext]
      if (!permission) {
        return false
      }

      return this.$can('access', permission)
    },

    _isMultipleGroupIDs() {
      const allowedContexts = ['comparison']
      return allowedContexts.includes(this._getCurrentContext)
    },

    _canEngagementFavoriteFilters() {
      return this.$can('access', _permissions.engagement_favorite_filters)
    },
    _showFavoriteFilters() {
      if (!this._canEngagementFavoriteFilters) {
        return false
      }

      const allowedContexts = [
        'climate',
        'culture',
        'comparison',
        'participation',
        'opinions',
        'benchmark',
        'action-plan',
      ]
      return allowedContexts.includes(this._getCurrentContext)
    },
    _showActionPlansFilter() {
      const allowedRoutes = ['ActionPlansPage']
      return allowedRoutes.includes(this.$route.name)
    },
    _showOpinionsFilter() {
      const allowedRoutes = ['ReceivedOpinionsPage', 'ReceivedOpinionsListPage']
      return allowedRoutes.includes(this.$route.name)
    },

    /**
     * @todo apresentar MarketSegment e TeamSize
     * process.env.NODE_ENV === 'development'
     */
    _showMarketSegmentAndTeamSizeFilter() {
      if (process.env.NODE_ENV !== 'development') {
        return false
      }

      const allowedRoutes = ['RankingPage', 'InternalRankingPage']
      return allowedRoutes.includes(this.$route.name)
    },
    _hideMarketSegmentField() {
      return this.$route.name === 'InternalRankingPage'
    },

    /**
     * @todo Cultura apresentar DemographicReports
     * process.env.NODE_ENV === 'development'
     */
    _showDemographicReport() {
      const allowedContexts = ['climate']

      if (process.env.NODE_ENV === 'development') {
        allowedContexts.push('culture')
      }

      const showByPermissionOrToBuy = this.showByPermissionOrToBuy(
        this._canEngagementPageDemographicReports
      )

      return (
        allowedContexts.includes(this._getCurrentContext) &&
        showByPermissionOrToBuy
      )
    },

    _displayDemographicReportShowMoreAction() {
      return (
        this.demographicReports.value.length > 0 &&
        !this.demographicReports.noMoreResults &&
        !this.demographicReports.isLoading
      )
    },

    _showActionPlans() {
      const showByPermissionOrToBuy = this.showByPermissionOrToBuy(
        this._canEngagementActionPlans
      )

      const allowedContexts = ['climate', 'culture', 'participation']
      return (
        allowedContexts.includes(this._getCurrentContext) &&
        showByPermissionOrToBuy
      )
    },
    _displayActionPlanShowMoreAction() {
      return (
        this.actionPlans.value.length > 0 &&
        !this.actionPlans.noMoreResults &&
        !this.actionPlans.isLoading
      )
    },

    _showGroupPeople() {
      if (!this.getGroupId) {
        return
      }

      const allowedContexts = [
        'climate',
        'culture',
        'participation',
        'benchmark',
        'opinions',
        'action-plan',
      ]
      return allowedContexts.includes(this._getCurrentContext)
    },
  },

  methods: {
    ...mapActions([
      'setGroupId',
      'setGroupIds',
      'setFavorites',
      'removeFavorite',
      'setDemographicReportID',
    ]),

    fetchData() {
      this.fetchActionPlans({ reset: true })
      this.fetchFavoriteFilters()
    },
    fetchAllData() {
      this.fetchData()
      this.fetchDemographicReports({ reset: true })
      this.fetchReportAttributes()
    },

    showByPermissionOrToBuy(
      canAccess,
      IsBasicPlanAdminUser = this.getIsBasicPlanAdminUser
    ) {
      if (canAccess) {
        return true
      }

      return IsBasicPlanAdminUser && !canAccess
    },

    async refreshActionPlansListener(options = { newPlan: false }) {
      if (options.newPlan) {
        this.fetchActionPlans({ reset: true }).then(() => {})
      } else {
        this.fetchActionPlans({ updateList: true })
      }
    },
    async fetchActionPlans(
      options = { updateList: false, reset: false, showMore: false }
    ) {
      const shouldNotFetch =
        !this._showActionPlans || !this._canEngagementActionPlans

      if (shouldNotFetch) {
        return
      }

      if (options.showMore) {
        this.actionPlans.offset += this.actionPlans.limit
      }

      if (options.reset) {
        this.actionPlans.offset = 0
        this.actionPlans.value = []
      }

      if (options.updateList) {
        this.actionPlans.offset = 0
        this.actionPlans.limit = this.actionPlans.value.length
        this.actionPlans.value = []
      }

      try {
        this.actionPlans.isLoading = true
        const { data } = await listActionPlans({
          groupID: this.getGroupId,
          type: this._getCurrentContext,
          limit: this.actionPlans.limit,
          offset: this.actionPlans.offset,
        })

        this.actionPlans.value = [...this.actionPlans.value, ...data]

        if (data.length < this.actionPlans.limit || data.length === 0) {
          this.actionPlans.noMoreResults = true
        } else {
          this.actionPlans.noMoreResults = false
        }

        if (options.updateList) {
          this.actionPlans.limit = 2
          this.actionPlans.offset = this.actionPlans.value.length - 2
        }
      } finally {
        this.actionPlans.isLoading = false
      }
    },

    async fetchFavoriteFilters() {
      if (!this._canEngagementFavoriteFilters) return

      this.favoriteFiltersLoading = true

      // Verificação se precisa realizar um novo get
      // Só é feito um novo get quando não possuir nenhum item, ou caso se difiram, como multi e não multi
      if (
        !!this.favorites.items &&
        !!this.favorites.items.length &&
        this._isMultipleGroupIDs == this.favorites.items[0].multi
      ) {
        setTimeout(() => {
          this.favoriteFiltersLoading = false
        }, 800)

        return
      }

      const params = {
        limit: this.favoriteFilters.limit,
        offset: this.favoriteFilters.offset,
        multi: this._isMultipleGroupIDs,
      }

      await getFavoriteFilters(params)
        .then(({ data }) => {
          this.setFavorites(data)
        })
        .finally(() => {
          this.favoriteFiltersLoading = false
        })
    },
    handleFavoriteSelect(item) {
      if (this._isMultipleGroupIDs) {
        const groupIds = item ? item.groups.map((e) => e.id) : null
        this.setGroupIds(groupIds)
        return
      }

      const groupId = item ? item.groups[0].id : null
      this.setGroupId(groupId)
    },
    handleFavoriteSave(item) {
      const id = item.id
      const payload = item.payload

      payload.multi = this._isMultipleGroupIDs
      payload.groups = item.groups

      saveFavoriteFilters(id, payload).then(() => {})
    },
    handleFavoriteRemove({ id }) {
      removeFavoriteFilters(id).then(() => {
        const index = this.favorites.items.map((e) => e.id).indexOf(id)
        if (index < 0) return

        this.removeFavorite({ index })
      })
    },

    getDemographicReportFetchMethod() {
      const methodsByContext = {
        climate: getDemographicReportsList,
        culture: getDemographicReportsListCulture,
      }

      const fetchReportsMethod = methodsByContext[this._getCurrentContext]

      return fetchReportsMethod
    },
    async fetchDemographicReports(options = { showMore: false, reset: false }) {
      const shouldNotFetchTheReport =
        !this._showDemographicReport ||
        !this._canEngagementPageDemographicReports ||
        this.demographicReports.isLoading

      if (shouldNotFetchTheReport) {
        return
      }

      const fetchReportsMethod = this.getDemographicReportFetchMethod()

      if (options.showMore) {
        this.demographicReports.offset += this.demographicReports.limit
      }

      if (options.reset) {
        this.demographicReports.offset = 0
        this.demographicReports.value = []
      }

      try {
        this.demographicReports.isLoading = true

        const { data } = await fetchReportsMethod({
          groupID: this.getGroupId,
          limit: this.demographicReports.limit,
          offset: this.demographicReports.offset,
        })

        this.demographicReports.value = [
          ...this.demographicReports.value,
          ...data,
        ]

        const hasReference = this.isAdemographicReportId(
          this.demographicReportID
        )

        if (!hasReference) {
          this.handleReportSelect({ id: null, unselect: true })
        }

        if (data.length < this.demographicReports.limit || data.length === 0) {
          this.demographicReports.noMoreResults = true
          return
        } else {
          this.demographicReports.noMoreResults = false
        }
      } catch (error) {
        this.showAlertBar({
          title: this.$t('error'),
          description: error.message,
          type: 'error',
        })
      } finally {
        this.demographicReports.isLoading = false
      }
    },
    async fetchReportAttributes() {
      if (
        !this._showDemographicReport ||
        !this._canEngagementPageDemographicReports
      ) {
        return
      }

      try {
        this.reportAttributes.isLoading = true

        const { data } = await getAttributesList({})
        this.reportAttributes.value = data
      } catch (error) {
        this.showAlertBar({
          title: this.$t('error'),
          description: error.message,
          type: 'error',
        })
      } finally {
        this.reportAttributes.isLoading = false
      }
    },
    async handleReportDelete(ID) {
      const methodsByContext = {
        climate: deleteDemographicReport,
        culture: deleteDemographicReportCulture,
      }

      const deleteReportMethod = methodsByContext[this._getCurrentContext]

      await deleteReportMethod(ID)
      this.fetchDemographicReports({ reset: true })
    },

    handleReportSelect({ id, unselect = false }) {
      this.demographicReports.reportWillBeUnselect = unselect
      this.demographicReports.reportIsSelected = !!id

      this.setDemographicReportID(id)
    },
    isAdemographicReportId(ID) {
      const report = this.demographicReports.value.find(
        (report) => report.id === ID
      )

      return !!report
    },

    createValidationErrorMessage(invalidAttributes) {
      let attributesMessage = ''

      invalidAttributes.forEach((attribute, index) => {
        const isTheLastItem = invalidAttributes.length === index + 1
        const separator = isTheLastItem ? '' : ', '

        attributesMessage += `${attribute.key}: ${attribute.value}${separator}`
      })

      const description = `
        ${this.$t('demographicReports.errorValidaionMessagePt1')}
        <br /><br />
        <p>${this.$t('demographicReports.errorValidaionMessagePt2', {
          attributes: `<b>${attributesMessage}</b>`,
        })}</p>
      `
      return {
        title: this.$t('demographicReports.errorValidationTitle'),
        description,
        type: 'error',
        timeout: 20000,
      }
    },

    async handleGenerateReport(attributes) {
      const methodsByContext = {
        climate: createDemographicReport,
        culture: createDemographicReportCulture,
      }

      const createMethod = methodsByContext[this._getCurrentContext]

      try {
        const { data } = await createMethod({
          groupID: this.getGroupId,
          attributes,
        })

        this.demographicReports.value = [data, ...this.demographicReports.value]

        this.showAlertBar({
          title: this.$t('demographicReports.successTitleMessage'),
          description: this.$t('demographicReports.succcessMessage'),
          timeout: 20000,
          type: 'success',
        })
      } catch (error) {
        let alertData

        const isAvalidationError =
          error.response && error.response.status === 400

        if (isAvalidationError) {
          let invalidAttributes = error.response.data.data.invalidAttributes
          alertData = this.createValidationErrorMessage(invalidAttributes)
        } else {
          alertData = {
            title: this.$t('demographicReports.errorValidationTitle'),
            description: error.message,
            type: 'error',
          }
        }

        this.showAlertBar(alertData)
      }
    },

    showAlertBar(data) {
      this.$root.$emit('display-alert', data)
    },
  },

  watch: {
    $route() {
      if (this.$refs.smartAttributes) {
        this.$refs.smartAttributes.clearForm()
      }

      if (this.demographicReports.reportIsSelected) {
        this.handleReportSelect({ id: null, unselect: true })
        this.demographicReports.reportWillBeUnselect = false
      }

      this.fetchAllData()
    },

    getGroupId() {
      this.fetchData()
      this.fetchDemographicReports({ reset: true })
    },
  },

  created() {
    this.fetchAllData()
    this.$root.$on('update-action-plan', this.refreshActionPlansListener)
  },

  beforeDestroy() {
    this.$root.$off('update-action-plan', this.refreshActionPlansListener)
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
