<template>
  <v-form ref="form" v-model="valid">
    <section class="person-form">
      <div class="header-data">
        <div
          class="horizontal-inputs-add flex-wrap flex-sm-nowrap justify-center"
        >
          <div class="container-add">
            <UploadIMG
              data
              :link-image="form.photo"
              @handleInput="handleInput($event, 'photo')"
            ></UploadIMG>
          </div>

          <div>
            <v-row class="mx-n2">
              <v-col cols="12" class="px-2 mb-4">
                <CustomInput
                  ref="name"
                  data-test-name-input
                  :label="$t('PeoplePage.newPerson.about.name')"
                  :mandatory="true"
                  @handleInput="handleInput($event, 'name')"
                ></CustomInput>
              </v-col>

              <v-col cols="12" md="6" class="px-2 mb-4">
                <CustomInput
                  ref="email"
                  data-test-email-input
                  :label="$t('PeoplePage.newPerson.about.email')"
                  :mandatory="true"
                  @handleInput="handleInput($event, 'email')"
                ></CustomInput>
              </v-col>

              <v-col cols="12" md="6" class="px-2 mb-4">
                <CustomInput
                  ref="code"
                  data-test-code-input
                  :label="$t('PeoplePage.newPerson.about.code')"
                  :mandatory="false"
                  @handleInput="handleInput($event.trim(), 'code')"
                ></CustomInput>
              </v-col>

              <v-col cols="12" md="6" class="px-2 mb-4">
                <CustomInput
                  ref="phoneNumber"
                  data-test-phone-input
                  mask="+## (##) #####-####"
                  :mandatory="false"
                  :label="$t('PeoplePage.newPerson.about.phoneNumber')"
                  @handleInput="handleInput($event, 'phoneNumber')"
                ></CustomInput>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>

      <DetailSectionAccordion
        class="person-form-new-details-section"
        data-test-details
        type="details"
        :title="$t('PeoplePage.newPerson.details.title')"
        :opened="true"
      >
        <div class="grid-inputs">
          <v-row class="mx-n6">
            <v-col cols="12" md="6" class="px-2 mb-4">
              <ItemSelector
                data-test-permission-groups-input
                :input-config="{
                  label: $t('PeoplePage.newPerson.details.permissionGroups'),
                  rules: rules,
                  showAvatar: false,
                }"
                :menu-config="{
                  attach: true,
                }"
                :menu-options="permissionGroupsOptions"
                :fill-width="true"
                :multiple="true"
                :show-avatar="false"
                :show-elements="true"
                :loading="loading.permissionGroups"
                @update:item="handleInput($event, 'permissionGroups')"
                @search:item="getPermissionGroups"
                @focus:input="getPermissionGroups()"
              ></ItemSelector>
            </v-col>

            <v-col cols="12" md="6" class="px-2 mb-4">
              <ItemSelector
                data-test-direct-manager-input
                :input-config="{
                  label: $t('PeoplePage.newPerson.details.directManager'),
                }"
                :menu-config="{
                  attach: true,
                }"
                :menu-options="managersOptions"
                :fill-width="true"
                :multiple="false"
                :show-avatar="true"
                :show-elements="true"
                :loading="loading.directManagers"
                @update:item="handleInput($event, 'directManager')"
                @search:item="getDirectManagers"
                @focus:input="getDirectManagers()"
              ></ItemSelector>
            </v-col>

            <v-col cols="12" md="6" class="px-2 mb-4">
              <ItemSelector
                data-test-occupation-input
                :input-config="{
                  label: $t('PeoplePage.newPerson.details.role'),
                }"
                :menu-config="{
                  attach: true,
                }"
                :menu-options="occupationRolesOptions"
                :fill-width="true"
                :multiple="false"
                :show-avatar="false"
                :show-elements="true"
                :local-search="true"
                :loading="loading.occupationRoles"
                @update:item="handleInput($event, 'occupation')"
                @focus:input="getPeopleRoles()"
              ></ItemSelector>
            </v-col>

            <v-col cols="12" md="6" class="px-2 mb-4">
              <DatePicker
                :input-config="{
                  label: $t('PeoplePage.newPerson.details.birthDate'),
                }"
                :menu-config="{
                  align: 'left',
                  attach: true,
                }"
                @update:item="handleInput($event, 'birthDate')"
              ></DatePicker>
            </v-col>
          </v-row>
        </div>
      </DetailSectionAccordion>

      <DetailSectionAccordion
        class="person-form-new-groups-section"
        data-test-groups
        type="people"
        :title="$t('PeoplePage.newPerson.groups.title')"
        :opened="true"
      >
        <DataTable
          ref="dataTable"
          class="data-table-margin-resized"
          :title="$t('PeoplePage.newPerson.groups.title')"
          :empty-placeholder="$t('PeoplePage.newPerson.groups.dataTable.empty')"
          :headers="_headers"
          :items="groupsSearch.length > 0 ? groupsSearch : form.groups"
          :add-options="selectorGroupOptions"
          :hide-add-elements="form.groups.map(el => el.id)"
          :unique-selection-options="true"
          :hide-header="true"
          :hide-option="true"
          :attach="true"
          :items-per-page="7"
          @add-data="handleAddGroup"
          @update-data="updateGroup"
          @delete="removeGroup"
          @search:item="getGroups"
          @search="searchGroup"
          @focus:input="getGroups()"
        ></DataTable>
      </DetailSectionAccordion>

      <div v-if="attributes.length">
        <section v-for="(item, i) in attributes" :key="i">
          <DetailSectionAccordion
            v-if="showSection(item) && item.items.length > 0"
            class="person-form-new-attributes-section"
            data-test-attributes
            :type="'details'"
            :title="`${$t('PeoplePage.newPerson.attributes.title')}${
              item.id ? `: ${item.label}` : ''
            }`"
            :opened="true"
          >
            <div class="grid-inputs">
              <v-row class="mx-n6">
                <v-col
                  v-for="(subitem, x) in item.items"
                  :key="x"
                  cols="12"
                  md="6"
                  class="px-2 mb-4"
                >
                  <ItemSelector
                    :multiple="true"
                    :fill-width="true"
                    :local-search="true"
                    :input-config="{
                      label: subitem.label,
                      subtleMode: false,
                      showAvatar: false,
                      clearable: true,
                    }"
                    :menu-config="{
                      attach: true,
                      showTabs: false,
                    }"
                    :current-value="{
                      data: getAttributesValues(subitem),
                      origin: 'meta',
                    }"
                    :menu-options="[
                      {
                        value: 'meta',
                        type: 'listview',
                        items: subitem.values,
                      },
                    ]"
                    @update:item="handleChangeAttribute(subitem, $event)"
                  ></ItemSelector>
                </v-col>
              </v-row>
            </div>
          </DetailSectionAccordion>
        </section>
      </div>
    </section>
  </v-form>
</template>

<script>
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { mapActions } from 'vuex'
import { createPerson, getPeople, getPeopleRoles } from '@/services/people'
import { getPeopleAttributes } from '@/services/attributes'
import { getGroups } from '@/services/groups'
import { getGroupsRoles } from '@/services/papers'
import { getPermissionGroups } from '@/services/permission-groups'
import UploadIMG from '../Partials/UploadIMG/UploadIMG'
import DataTable from '@/components/DataTable/DataTable.vue'

export default {
  name: 'NewPerson',
  components: {
    DataTable,
    UploadIMG,
  },
  data() {
    return {
      valid: false,
      loading: {
        permissionGroups: false,
        directManagers: false,
        occupationRoles: false,
        groups: false,
      },

      occupationRoles: [],
      directManagers: [],
      permissionGroups: [],
      groups: [],
      groupsSearch: '',
      groupRoles: {
        engagement: [],
        growth: [],
        performance: [],
      },
      attributes: [],
      form: {
        photo: '',
        name: '',
        email: '',
        phoneNumber: '',
        code: '',
        permissionGroups: [],
        directManager: {},
        occupation: {},
        birthDate: '',
        groups: [],
        attributes: [],
        language: 'pt-BR', // mock
      },
      rules: [
        v =>
          (!!v && v.length > 0) ||
          this.$t('PeoplePage.newPerson.requiredField'),
      ],
    }
  },

  computed: {
    mask() {
      return createNumberMask({
        prefix: '',
        includeThousandsSeparator: false,
        allowDecimal: true,
        allowNegative: false,
        decimalSymbol: this.$t('decimalSymbol'),
        decimalLimit: null,
      })
    },

    _headers() {
      return [
        {
          text: this.$t('PeoplePage.newPerson.groups.dataTable.headers.group'),
          value: 'group',
          type: 'entity-item',
        },
        {
          text: this.$t(
            'PeoplePage.newPerson.groups.dataTable.headers.engagement'
          ),
          value: 'engagement',
          type: 'combo-selection',
          options: this.groupRoles.engagement,
        },
        {
          text: this.$t('PeoplePage.newPerson.groups.dataTable.headers.growth'),
          value: 'growth',
          type: 'combo-selection',
          options: this.groupRoles.growth,
        },
        {
          text: this.$t(
            'PeoplePage.newPerson.groups.dataTable.headers.performance'
          ),
          value: 'performance',
          type: 'combo-selection',
          options: this.groupRoles.performance,
        },
        {
          text: this.$t(
            'PeoplePage.newPerson.groups.dataTable.headers.actions'
          ),
          value: 'actions',
          type: 'action-buttons',
        },
      ]
    },

    permissionGroupsOptions() {
      return [
        {
          value: 'groups',
          label: 'Grupos de permissões',
          type: 'listview',
          items: this.permissionGroups.map(el => {
            return {
              id: el.id,
              image: el.image,
              label: el.label,
            }
          }),
        },
      ]
    },

    managersOptions() {
      return [
        {
          value: 'managers',
          label: 'Pessoas',
          type: 'listview',
          items: this.directManagers.map(el => {
            return {
              id: el.id,
              image: el.photo,
              label: el.name,
              inactive: el.status === 'inactive',
            }
          }),
        },
      ]
    },

    occupationRolesOptions() {
      return [
        {
          value: 'occupationRoles',
          label: this.$t('PeoplePage.newPerson.details.role'),
          type: 'listview',
          items: this.occupationRoles,
        },
      ]
    },

    selectorGroupOptions() {
      return [
        {
          value: 'groups',
          label: 'Grupos',
          type: 'listview',
          items: this.groups.map(el => {
            return {
              id: el.id,
              image: el.image,
              label: el.name,
            }
          }),
        },
      ]
    },
  },

  watch: {
    attributes() {
      this.form.attributes = []
    },
  },
  beforeMount() {
    this.getPeopleAttributes()
    this.getGroupRoles()
  },

  methods: {
    ...mapActions(['setCurrentPage']),

    calcPayload() {
      const nullOrValue = value => {
        if ([null, undefined].includes(value)) return null
        if (Array.isArray(value) && value.length === 0) return null
        if (typeof value === 'string' && value.trim().length === 0) return null
        if (typeof value === 'object' && Object.keys(value).length === 0)
          return null
        if (Object.keys(value).includes('id')) {
          return !value?.id ? null : { id: value.id }
        }

        return value
      }

      const getPhoneNumber = value => {
        value = nullOrValue(value)
        if (!value) return null

        return '+' + value.replaceAll(/[^0-9]/gim, '')
      }

      return {
        ...this.form,
        photo: nullOrValue(this.form.photo),
        code: nullOrValue(this.form.code),
        phoneNumber: getPhoneNumber(this.form.phoneNumber),

        permissionGroups: this.form.permissionGroups
          .map(el => nullOrValue(el))
          .filter(Boolean),
        directManager: nullOrValue(this.form?.directManager),
        occupation: nullOrValue(this.form?.occupation),
        birthDate: nullOrValue(this.form.birthDate),

        groups: this.form.groups.map(el => ({
          id: el.id,
          roles: {
            engagement: el.engagement,
            growth: el.growth,
            performance: el.performance,
            management: el.management,
          },
        })),

        attributes: this.form.attributes
          .map(el => ({
            id: el.id,
            values: el.values?.map(el => nullOrValue(el)).filter(Boolean) || [],
          }))
          .filter(el => el.values.length),
      }
    },

    async submit(sendInvite = false) {
      if (!this.validate()) return

      try {
        this.$emit('loading', true)

        const payload = {
          body: {
            sendInvite,
            ...this.calcPayload(),
          },
        }

        await createPerson(payload).then(() => {
          this.displayAlert(
            'success',
            this.$t('PeoplePage.newPerson.successMessage')
          )

          this.$emit('loading', false)
          this.$emit('close', 'close')
        })
      } catch (err) {
        this.displayAlert(
          'error',
          err?.response?.data?.validation?.message ||
            err?.response?.data?.message ||
            this.$t('PeoplePage.newPerson.errorMessage')
        )
      } finally {
        this.$emit('loading', false)
      }
    },

    validate() {
      const forms = ['form', 'name', 'email']
      let valid = true

      forms.forEach(e => {
        const value = this.$refs[e].validate()
        if (!value && value != undefined) valid = false
      })

      if (!valid) {
        const erros = this.$el.getElementsByClassName('error--text')

        setTimeout(() => {
          const el = erros[0]
          if (el) el.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }, 0)
      }

      return valid
    },

    handleInput(value, inputName) {
      this.form[inputName] = value
    },

    async getPermissionGroups(search = '') {
      this.loading.permissionGroups = true

      const payload = {
        limit: 30,
        offset: 0,
        ...(search && { label: search }),
      }

      await getPermissionGroups(payload)
        .then(res => {
          this.permissionGroups = res.data
        })
        .finally(() => {
          this.loading.permissionGroups = false
        })
    },

    async getDirectManagers(search = '') {
      this.loading.directManagers = true

      const payload = {
        limit: 30,
        offset: 0,
        showInactive: true,
        ...(search && { name: search }),
      }

      await getPeople(payload)
        .then(res => {
          this.directManagers = res.data
        })
        .finally(() => {
          this.loading.directManagers = false
        })
    },

    async getPeopleRoles() {
      this.loading.occupationRoles = true

      await getPeopleRoles()
        .then(res => {
          this.occupationRoles = res.data
        })
        .finally(() => {
          this.loading.occupationRoles = false
        })
    },

    handleAddGroup(event) {
      const group = {
        id: event.id,
        group: {
          image: event.image,
          title: event.label,
        },
        engagement: [],
        growth: [],
        performance: [],
        management: [],
        actions: ['delete'],
      }

      this.form.groups.unshift(group)
    },

    updateGroup(event) {
      const index = this.form.groups.findIndex(el => el.id === event.id)
      this.form.groups[index][event.key] = event.values

      if (this.groupsSearch.length > 0) {
        const index = this.groupsSearch.findIndex(el => el.id === event.id)
        this.groupsSearch[index][event.key] = event.values
      }
    },

    removeGroup(event) {
      const index = this.form.groups.findIndex(el => el.id === event.id)
      this.form.groups.splice(index, 1)

      if (this.groupsSearch.length > 0) {
        const index = this.groupsSearch.findIndex(el => el.id === event.id)
        this.groupsSearch.splice(index, 1)

        if (this.groupsSearch.length === 0) this.$refs.dataTable.clearSearch()
      }
    },

    searchGroup(event) {
      const array = []

      if (event) {
        this.form.groups.map(el => {
          if (el.group.title.toLowerCase().includes(event.name.toLowerCase()))
            array.push(el)
        })
      }

      this.groupsSearch = array
    },

    async getGroups(search = '') {
      this.loading.groups = true

      const payload = {
        limit: 30,
        offset: 0,
        name: search,
      }

      await getGroups(payload)
        .then(res => {
          this.groups = res.data
        })
        .finally(() => {
          this.loading.groups = false
        })
    },

    async getGroupRoles() {
      const payload = {
        limit: 500,
        offset: 0,
      }

      await getGroupsRoles(payload).then(res => {
        this.groupRoles = res.data
      })
    },

    async getPeopleAttributes() {
      const payload = {
        limit: 99,
        offset: 0,
        translated: true,
      }

      await getPeopleAttributes(payload).then(res => {
        const attributes = [
          {
            items: [],
          },
        ]

        res.data.forEach(el => {
          if (!el.group) attributes[0].items.push(el)
          else {
            const index = attributes.findIndex(
              attribute => attribute.id === el.group.id
            )

            if (index >= 0) attributes[index].items.push(el)
            else
              attributes.push({
                ...el.group,
                items: [el],
              })
          }
        })

        this.attributes = attributes
      })
    },

    handleChangeAttribute(attribute, event) {
      const data = {
        id: attribute.id,
        label: attribute.label,
        values: event,
      }

      const index = this.form.attributes.findIndex(
        groupAttributes => groupAttributes.id === attribute.id
      )

      if (index !== -1) {
        this.form.attributes[index] = {
          ...data,
        }
      } else {
        this.form.attributes.push(data)
      }
    },

    getAttributesValues(attribute) {
      const values = this.form.attributes.find(
        groupAttributes => groupAttributes.id === attribute.id
      )

      if (values) {
        return values.values
      }

      return []
    },

    showSection(data) {
      for (let i = 0; i < data.items.length; i++) {
        if (!data.items[i].readonly) return true
      }

      return false
    },

    displayAlert(type, description) {
      this.$emit('display-alert', { type, description })
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
